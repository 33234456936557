/** @jsx jsx */
import { jsx } from "theme-ui";
import Content from "@src/templates/content";
import ImageTextBlock from "@src/components/ImageTextBlock";
import SectionHeadingBlock from "@src/components/SectionHeadingBlock";
import Container from "@src/components/Container";
import CTABanner from "@src/components/CTABanner";
import { Link } from "gatsby";
export default ({ location }) => (
  <Content
    pageTitle="Our comics"
    pageDescription="Site-specific, past-loving, future-looking: the comics produced by Bernard Caleo are set in
  Australian places, and the storylines have been written out of the histories of these places."
    metaDescription="Bernard’s comics are set in places where he has been (eg the city and suburbs of Melbourne, country Victoria, the town of Minamisanriku in Japan),and often arise from collaborations or conversations."
    location={location}
  >
    <div>
      <SectionHeadingBlock heading="Digital Comics: Bernard creates comics for delivery on the web.">
        <div>
          <Container>
            <ImageTextBlock
              alt="Bernard sitting on table in front of sketches with hands up"
              src="/images/potters-point.jpeg"
              body={
                <div>
                  <h2>The Potter's Point</h2>

                  <p>
                    ‘The Potter’s Point’ is an online comic strip produced for
                    Melbourne City of Literature as part of their ‘Reading
                    Victoria’ project. This comic strip is about the real-life
                    artist and potter Eric Juckert and his life at Grossard
                    Point on Phillip Island. The story was researched by
                    interviewing islanders who had known Eric, and discovering a
                    story from their anecdotes.
                  </p>

                  <a
                    href="https://readingvictoria.cityofliterature.com.au/2018/10/04/ventnor/"
                    target="_blank"
                  >
                    Read the Potters Point
                  </a>
                </div>
              }
            />
            <ImageTextBlock
              alt="Flamingo illustration"
              src="/images/flamingos.jpeg"
              body={
                <div>
                  <h2>The Future is Flamingoes</h2>

                  <p>
                    ‘The Future is Flamingoes’ is a 12-page comic booklet
                    produced for the City of Darebin as part of their ‘Writing
                    this Place’ project. The story is set in the Darebin
                    Parklands, and stars a talking duck (of course). The
                    storyline arose from a walk along the Darebin Creek with the
                    parklands Ranger of 30 years, Peter Wiltshire.
                  </p>

                  <a
                    href="https://www.darebinarts.com.au/wp-content/uploads/2017/08/City-Of-Darebin-Writing-this-Place-.pdf"
                    target="_blank"
                  >
                    Read the ‘The Future is Flamingoes’
                  </a>
                </div>
              }
            />
            <ImageTextBlock
              alt="Bernard sitting on table in front of sketches with hands up"
              src="/images/case-made-australia.jpg"
              body={
                <div>
                  <h2>The Case that Made Australia</h2>

                  <p>
                    Here’s a movie comic made by Bruce Woolley and Bernard for
                    the The Museum of Australian Democracy at Old Parliament
                    House in Canberra. It’s about a ground-breaking moment in
                    Australian history, the granting of property rights to
                    convicts in Sydney Cove in 1788, and it stars stately
                    Governor Arthur Phillip, a couple of conniving convicts, and
                    a nefarious sea captain. The story arose from conversations
                    with historian Alex McDermott, images by Bernard and the
                    music was composed and performed by Bruce Woolley.
                  </p>

                  <a
                    href="https://www.youtube.com/watch?v=cKu4DezV7EM"
                    target="_blank"
                  >
                    Watch ‘The Case that Made Australia’
                  </a>
                </div>
              }
            />
          </Container>
        </div>
      </SectionHeadingBlock>
      <SectionHeadingBlock heading="Print Comics: Bernard creates comics for print publication.">
        <div>
          <Container>
            <ImageTextBlock
              alt="Bernard sitting on table in front of sketches with hands up"
              src="/images/comic-bunch.jpg"
              body={
                <div>
                  <p>
                    Bernard Caleo has worked as writer, artist, collaborator,
                    editor, and publisher on many comic book titles including
                    Tango (with many others), Mongrel, Yell Olé! (with Tolley),
                    The False Impressionists (with Tolley), Flâneur, Café Ghetto
                    (with John Murphy), I Knew Him, The Element of Surprise, and
                    ‘Graphic Shakespeare’ (with Rob Conkie).
                  </p>
                  <p>
                    For Bernard the experience of holding a comic and breathing
                    in its world as you read it, the feel of the pages and the
                    smell of the ink, is wonderful. Humans are animals: we like
                    physical things.
                  </p>
                </div>
              }
            />
          </Container>
        </div>
      </SectionHeadingBlock>
      <SectionHeadingBlock heading="Current Comics Projects">
        <div>
          <Container>
            <ImageTextBlock
              body={
                <div>
                  <p>
                    Bernard is currently working with historian Alex McDermott
                    on a visual story examining the decline and fall of
                    Marvellous Melbourne in the 1880s. The Devil Collects stars
                    real historical figures, including the later-to-be Prime
                    Minister Alfred Deakin, newspaper owner Maurice Brodzky, and
                    entrepreneur businesswoman Madame Brussels.
                  </p>

                  <a
                    href="https://www.instagram.com/bernard.caleo/?hl=en"
                    target="_blank"
                  >
                    See work-in-progress drawings for The Devil Collects
                  </a>
                </div>
              }
            />
          </Container>
        </div>
      </SectionHeadingBlock>

      <CTABanner
        lines={[
          <div>
            <Link to="/contact">Contact Bernard</Link> to discuss the
            development of a comic for your business, publication or project.
          </div>,
        ]}
      />
    </div>
  </Content>
);
